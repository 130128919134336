












































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { PublicBoardTemplatePaginator } from "@/typescript/types";
import { PublicBoardTemplates } from "@/graphql/boardTemplate";
import NorthStarService from "@/NorthStarService";
import debounce from 'lodash/debounce';
//@ts-ignore
import VLazyImage from "v-lazy-image/v2";

@Component({
  components: {VLazyImage},
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
      variables() {
        return {
          page: this.publicBoardTemplatesPageCount
        }
      },
      result(result: any) {
        if (
          result &&
          result.data &&
          result.data.PublicBoardTemplates &&
          this.PublicBoardTemplatesData &&
          this.PublicBoardTemplatesData.data.length > 0
        ) {
          this.PublicBoardTemplatesData.data = this.filterDuplicateData([
            ...this.PublicBoardTemplatesData.data,
            ...result.data.PublicBoardTemplates.data
          ]);
          this.PublicBoardTemplatesData.paginatorInfo = result.data.PublicBoardTemplates.paginatorInfo;
        } else {
          this.PublicBoardTemplatesData = result.data.PublicBoardTemplates;
        }
      },
    },
  }
})
export default class CuratedGoalsSelector extends Vue {
  // Local component data
  publicBoardTemplatesPageCount: number = 1;
  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;
  selectedTag: string = 'all';
  highlightDone: boolean = false;
  onboardingStep: number = 0;
  selectedSuggestions: Array<string> = [];
  selectedObjectives: Array<string> = [];
  addedObjectivesCount: number = 0;
  service: NorthStarService | null = null;
  isLoading: boolean = false;
  addedBoard: string | null = null;
  nextStartDate: Date = this.getFirstDayOfCurrentMonth();
  newNorthStarId: string = '';
  isScrollingToAll: boolean = false;
  activityAdded: boolean = false;
  showSkipButton: boolean = false;
  nsName: string | null = null;

  private observer: IntersectionObserver | null = null;

  debouncedHandleNorthStarClick = debounce(this.handleNorthStarClick, 300);
  debouncedHandleCopyObjective = debounce(this.handleCopyObjective, 300);

  presetGoals = [
    { id: 1, name: "Start from scratch", templateId: "42069",
  
    },
    { id: 2, name: "Goal Two", templateId: "42069" },
    { id: 3, name: "Goal Three", templateId: "142069"  }
  ];

  colors: Array<string> = [
    '#7b7343',
    '#4a677d',
    '#773f75',
    '#bd5fb4',
    '#4a677d',
    '#374ea9',
    '#776015',
    '#2e825f',
    '#4e6068',
    '#66737a',
    '#717171',
    'No color'
  ];

  // Computed properties to access Vuex state via getters
  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get selectedNorthStars() {
    return this.$store.state.selected_northstars;
  }

  get selectedTemplates() {
    return this.$store.state.selected_templates;
  }

  // Computed property to get addedNorthstars as an object
  get addedNorthstars(): Record<string, string> {
    return this.$store.getters.addedNorthstars;
  }

  // Computed property to get addedNorthstars as an array
  get addedNorthstarsArray(): string[] {
    return Object.values(this.addedNorthstars);
  }

  // Computed property to get the count of addedNorthstars
  get addedNorthstarsCount(): number {
    return this.addedNorthstarsArray.length;
  }

  get filteredTemplates() {
    if (!this.PublicBoardTemplatesData || !this.PublicBoardTemplatesData.data) {
      return [];
    }
    return this.PublicBoardTemplatesData.data.reduce((acc: Array<any>, template: any) => {
      const filteredNS = Array.isArray(template.northStars)
        ? template.northStars.filter((ns: any) => this.selectedNorthStars.includes(ns.id))
        : [];

      if (filteredNS.length) {
        acc.push({ ...template, northStars: filteredNS });
      }
      return acc;
    }, []);
  }

  get addedNorthStarCountFromBoard() {
    return this.$store.state.board?.northStars.length || 0;
  }

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }

  get inAppOnboardingStep() {
    return this.$store.state.in_app_onboarding_step;
  }
 
  mounted() {
    this.service = new NorthStarService(this.$apollo);

    this.$gtag.event('pmb_user_mounted_template_onboarding');
    window.addEventListener('popstate', this.handleBackButton);

    if (this.addedNorthStarCountFromBoard !== 0 && this.templateOnboarding) {
      this.onboardingStep = 1;
    } else {
      this.onboardingStep = 0;
    }

    setTimeout(() => {
      if(this.addedNorthstarsCount === 0) {
        this.showSkipButton = true;
      }
    }, 15000);

    

    this.nextStartDate = this.getFirstDayOfCurrentMonth();

    this.initIntersectionObserver();
  }

  getFirstDayOfCurrentMonth(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 14);
  }

  initIntersectionObserver() {
    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.5, 
    };

    this.observer = new IntersectionObserver(this.handleIntersect, options);


    const sections = document.querySelectorAll('.curated_template_category');
    sections.forEach((section) => {
        this.observer?.observe(section);
    });
  }

  handleIntersect(entries: IntersectionObserverEntry[]) {
    if (this.isScrollingToAll) {
      return; 
    }

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.getAttribute('id');
        if (id && id !== this.selectedTag) {
          this.selectedTag = id;
        }
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
    if (this.observer) {
      this.observer.disconnect();
    }
  }


  addParentIdToObjectives(objectives: any[], parentId: string) {
    return objectives.map((objective) => {
      return {
        ...objective,
        parent_id: parentId,
      };
    });
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  publicBoardTemplatesLoadMore() {
    this.publicBoardTemplatesPageCount++;
    this.$apollo.queries.PublicBoardTemplates.refetch();
  }

  onboardingCreateNew() {
    this.$events.fire('trigger_add_northstar', true);
    this.completeOnboarding();
  }

  handleContinue() {
    if (this.onboardingStep === 1 || this.inAppOnboardingStep) {
      this.completeOnboarding();

    } else {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.onboardingStep++;
        this.$gtag.event('pmb_user_reached_second_step_in_template_onboardng');

      }, 1000);
    }
  }
  
  completeOnboarding() {
    //Complete modal onboarding, start in-app onboarding
    this.$store.commit('set_template_onboarding', false);
    this.$store.commit('set_currently_in_activation_step', false);
    // this.$store.commit('set_in_app_onboarding_step', 3);
    // this.$events.fire('onboarding_goal_created');
    // this.$store.commit('set_display_onboarding_window', true);
    // this.$events.fire('template_onboarding_completed', true);
    //Set notification for onboarding chat message

    this.$store.commit('set_in_app_onboarding_step', 3);
    this.$gtag.event('pmb_user_reached_onboarding_step_3');


    setTimeout(() => { 
      this.$store.commit('set_in_app_onboarding_step', 4);
    }, 3000);

    this.$store.commit('add_chat_notification', this.board.id);
    this.$store.commit('set_display_onboarding_chat', true);

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.$gtag.event('pmb_user_completed_template_onboarding');
      this.closeModal();
    }, 1000);
  }

  closeModal() {
    this.$store.commit('clear_added_northstars');
    this.$store.commit('clear_selected_northstars');
    this.$store.commit('clear_selected_templates'); 
    // @ts-ignore
    this.$parent.close();
  }
  
  

  // handleNorthStarClick(northstarId: string, templateId: string) {
  //   this.$gtag.event('pmb_user_clicked_ns_template_onboarding');
  //   if (this.selectedNorthStars.includes(northstarId)) { 
  //     this.deleteItem(northstarId);
  //     this.removeNorthStarandTemplate(northstarId, templateId);
  //     // No direct modification of addedNorthstarsCount
  //   } else {
  //     if (this.addedNorthstarsCount < 5 && this.templateOnboarding) { 
  //       this.copyNorthstar(northstarId);
  //       this.addNorthStarandTemplate(northstarId, templateId);
  //       // No direct modification of addedNorthstarsCount
  //     } else {
  //       this.highlightDone = true;
  //       setTimeout(() => {
  //         this.highlightDone = false;
  //       }, 2000);
  //     }
  //   }
  // }

  handleNorthStarClick(northstarId: string, templateId: string) {
    this.$gtag.event('pmb_user_clicked_ns_template_onboarding');

    // if(this.inAppOnboardingStep) {
    //   this.$store.commit('set_in_app_onboarding_step', 3);
    //   this.copyNorthstar(northstarId);
   

    //   this.completeOnboarding();
    // } else {
    
    // If already selected, deselect
    if (this.selectedNorthStars.includes(northstarId)) { 
      this.deleteItem(northstarId);
      this.removeNorthStarandTemplate(northstarId, templateId);
    } else {

      this.copyNorthstar(northstarId);
      this.addNorthStarandTemplate(northstarId, templateId);

      //If onboarding, enforce the 5 northstar limit
      // if(this.templateOnboarding) {
      //   if (this.addedNorthstarsCount < 3) { 
      //     this.copyNorthstar(northstarId);
      //     this.addNorthStarandTemplate(northstarId, templateId);
      //   } else {
      //     this.highlightDone = true;
      //     setTimeout(() => {
      //       this.highlightDone = false;
      //     }, 2000);
      //   }
      // } else if(!this.templateOnboarding) {
      // }
    }
    
  }



  removeNorthStarandTemplate(northstarId: string, templateId: string) {
    this.$store.commit('remove_selected_northstars', northstarId);
    this.$store.commit('remove_selected_templates', templateId);
  }

  addNorthStarandTemplate(northstarId: string, templateId: string) {
    this.$store.commit('add_selected_northstars', northstarId);
    this.$store.commit('add_selected_templates', templateId);
  }

  handleCopyObjective(objective: any) {
    if (!this.ifObjectiveAlreadyAdded(objective.id)) {

      const parentId = objective.parent_id;
      this.addedObjectivesCount++;

      const existingNorthstarId = this.addedNorthstars[parentId];
      this.copyObjective(objective.id, existingNorthstarId);
      this.selectedObjectives.push(objective.id);
    } else {
      return;
    }
    
  }

  copyObjective(objectiveId: string, northstarId: string) {
    let boardId = this.$store.state.board?.id || null;
    if (boardId && northstarId) {
      this.service?.copyObjectiveForNorthStar(northstarId, objectiveId, boardId)
        .then((res) => {
          this.$events.fire('objective-created-from-northstar', {
            objective: res.data.copyObjectiveForNorthstar,
            northStarId: northstarId,
          });
          this.$gtag.event('pmb_user_created_objective_in_template_onboarding');
        })
        .catch((error) => {
          console.error('Failed to copy objective:', error);
        });
    }
  }

  scrollToSection(id: string) {
    if (id === 'all') {
    // Scroll to the top of the scrollable container
      const container = this.$refs.scrollContainer as HTMLElement;
      if (container) {
        container.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
      // Fallback to window scroll if container ref is not set
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.selectedTag = 'all';
      // Optionally, set a flag to ignore IntersectionObserver updates temporarily
      this.isScrollingToAll = true;
      setTimeout(() => {
        this.isScrollingToAll = false;
      }, 1000); // Adjust the timeout as needed
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        this.selectedTag = id;
      }
    }
  }



  handleBackButton() {
    this.closeModal();
  }

 

  copyNorthstar(northstar_id: string) {
    this.$gtag.event('pmb_create_ns_from_templates');

    if (this.ifAlreadyAdded(northstar_id)) {
      return;
    }

    if (this.$store.state.board) {
      this.addedBoard = this.$store.state.board.id;
    }

    if (this.selectedSuggestions.length === 0 || this.addedBoard != null) {
      this.selectedSuggestions.push(northstar_id);

      // Clone the current nextStartDate to avoid mutating the original date
      const currentStartDate = new Date(this.nextStartDate);
      const currentDueDate = null;
      // currentDueDate.setDate(currentDueDate.getDate() + 6); 

      
      this.service?.copyNorthStar(northstar_id, this.addedBoard, this.workspace.id).then((res: any) => {
        if (res.data.copyNorthStar && res.data.copyNorthStar.boards.length > 0) {
          const newNorthStar = res.data.copyNorthStar;
          this.newNorthStarId = newNorthStar.id;

          this.$store.commit('add_added_northstar', { key: northstar_id, value: this.newNorthStarId });
          this.$store.commit('set_onboarding_northstar_id', res.data.copyNorthStar.id);


          this.$events.fire('new_northstar_created', res.data.copyNorthStar);
          this.$gtag.event('pmb_user_created_ns_in_template_onboarding');
          this.addedBoard = newNorthStar.boards[0].id;
          this.$store.commit('set_newly_created_northstar_count', 
            (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) 
              ? 1 
              : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1
          );
          this.$store.commit('set_newly_created_northstar_in_board', res.data.copyNorthStar.id);

          this.$events.fire('northstars-updated-my-northstar', newNorthStar);
        }

        // If onboarding, update NorthStar dates with the calculated start and due dates
        if(this.inAppOnboardingStep) {
        this.service?.updateNorthStarDates(this.newNorthStarId, currentStartDate, currentDueDate).then((res: any) => {
          this.$events.fire('northstars-updated-my-northstar', res);
          console.log("NorthStar dates set");
        }).catch((error: any) => {
          console.error('Failed to update NorthStar dates:', error);
        });
        // Increment nextStartDate by 7 days for the next copy
        this.nextStartDate.setDate(this.nextStartDate.getDate() + 7);
        }

        
      }).catch((error: any) => {
        console.error('Failed to copy NorthStar:', error);
      });
    }
  }


  ifAlreadyAdded(northstarId: string) {
    return this.addedNorthstarsArray.includes(northstarId);
  }

  ifObjectiveAlreadyAdded(objectiveId: string) {
    return this.selectedObjectives.includes(objectiveId);
  }

  deleteItem(id: string) {
    let northstarToDeleteId = id;

    // Check if the id exists in addedNorthstars
    if (this.addedNorthstars[id]) {
      northstarToDeleteId = this.addedNorthstars[id];
      this.$store.commit('remove_added_northstar', id);
    }

    // Remove the northstar ID from selectedSuggestions
    const index = this.selectedSuggestions.indexOf(id);
    if (index !== -1) {
      this.selectedSuggestions.splice(index, 1);
    }

    // Proceed to delete the NorthStar
    this.service?.deleteNorthStar(northstarToDeleteId).then(() => {
      this.$events.fire('north-star-delete', northstarToDeleteId);
    });
  }

  @Watch('addedObjectivesCount')
  onOnboardingStepChange() {
    this.activityAdded = true;
    setTimeout(() => 
      this.activityAdded = false , 1000);

  }

}
